
.text-top{

    background-color: rgba(255, 255, 255, 1);
    width: fit-content;
    margin-right: auto;
    margin-left: 0;
    text-align:start;
    transition: font-size 0.3s ease;
}


.text-bottom{
    font-weight: 10!important;
    border-radius: 0.1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 10%;
    padding-right: 10%;
    margin-left: 10%;
    margin-right: 10%;

    background-color: rgba(255, 255, 255, 1);

    text-align:start;
    text-wrap: wrap;
    overflow:visible;
    transition: all 0.3s ease;
}

  @keyframes changeColor {
    0% { background-color: rgba(209, 209, 209, 0.2); } /* Starting color and opacity */
    25% { background-color: rgba(252, 217, 217, 0.2); }
    25% { background-color: rgba(164, 184, 187, 0.5); } /* Intermediate color and opacity */
    100% { background-color: rgba(209, 209, 209, 0.2); } /* Final color and opacity */
  }

  @media (max-width: 768px) {
    .text-top {
        font-size: 20px!important; /* Adjust font size for screens smaller than 768px */
    }
    .text-bottom{
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        margin-left: 1%;
        margin-right: 1%;

        font-size: 14px!important; 
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .bg-blur.text-bottom {
        font-size: 18px!important; /* Adjust font size for screens between 768px and 1024px */
    }
    .text-top {
        font-size: 20px!important; /* Adjust font size for screens smaller than 768px */
    }

    .text-bottom{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 1rem;
        padding-bottom: 1rem;

        margin-left: 2rem;
        margin-right: 2rem;

        font-size: 14px!important; 
    }
}


@media (min-width: 1024px) {
    .bg-blur.text-bottom {
        font-size: 20px !important; /* Adjust font size for screens larger than 1024px */
    }
    .text-top {
        font-size: 28px!important; /* Adjust font size for screens smaller than 768px */
    }
}

.half-photo{
    max-width: 50%;
}