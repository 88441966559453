
  .client-container{
    transition: scale 1s ease;
  }

  .client-container:hover{
    scale: 1.1;
  }
  
  .client-card {
    margin: auto;
    height: 200px; /* Set a uniform height for all client-card containers */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }
  
  .client-logo {
    width: 95%;
    height: 95%;
    object-fit: contain; /* Ensures the image fills the card without distortion */
  }
  