.a{
    color: inherit; /* blue colors for links too */
    text-decoration: none; /* no underline */
}

.Link{
    color: inherit; /* blue colors for links too */
    text-decoration: none; /* no underline */
}


.fa-flip{
    --fa-animation-iteration-count : 1;
    --fa-flip-x : 1.5;
    --fa-flip-y : 1;
    --fa-animation-duration: 1.2s;
}

.category-option{
    width: fit-content;
    margin: auto;
    z-index: 4;
    background: transparent;
    position: relative;
    z-index: 0; /* Ensure this element is above the overlay */
    cursor: pointer;
    transition: transform 0.3s ease, z-index 0.3s ease, background 1s ease, all 1s ease;
}

.category-option:hover {
    scale: 1.3;
    padding-left: 5rem;
    padding-right: 5rem;
    background: white;
    z-index: 3;
}

.blackOverlay {
    background: rgba(0, 0, 0, 0); /* Start with transparent */
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 0; /* Ensure the overlay is below the target element */
    transition: background 1s ease; /* Smooth transition */
    pointer-events: none; /* Ensure it doesn't block interactions */
    background-attachment: absolute;
    background-size: contain;
    background-position: center;
    position: fixed;
}

.blackOverlay.darkened {
    background: rgba(0, 0, 0, 0.3);
}

.services-overlay{
    z-index: 5;
    width: 30vw;
    top: 30dvh;
    background-color: rgba(255, 255, 255, 1);
    padding: 2rem;
    position: fixed;
    right: -30vw;
    transition: all 1s ease;
}


.services-details{
    right: 0rem!important; 
    transition: all 1s ease;
}
