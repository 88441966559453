.loading {
    display: inline-block; /* Ensures proper display */
    size: contain;
    animation: rotate 1s linear infinite; /* Animation properties */
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg); /* Start at 0 degrees */
    }
    100% {
      transform: rotate(360deg); /* Complete rotation */
    }
  }
  
  .placeholder{
    object-fit: cover!important;
    border-radius: 0 !important;
    aspect-ratio: 930/630;
    border: 0.3rem solid rgba(255, 255, 255, 0.5)!important;
  }