.link{
    text-decoration: none!important;
    color: black!important;
    transform: all 1s linear!important;
}

.link:hover{
    color: rgb(0, 10, 151)!important;
    transform: all 1s linear!important;
}

.map-component{
    border: 1rem solid white;
    margin-right: 5%;
    margin-left: 5%;
}

.scroll{
    overflow: hidden;
}

.contact-option{
    width: fit-content;
    margin: auto;
    z-index: 1;
    scale: 1;
    transition: all 1s ease!important;
}

.contact-map{
    width: auto;
}

.contact-option:hover{
    z-index: 2;
    background: white;
    scale: 1.03;
}

.contact-option:hover ~.pageBody{
    background: rgba(0, 0, 0, 0.5)!important;
}

.contact-option{
    padding: 1rem;
    background: transparent;
    position: relative;
    z-index: 0; /* Ensure this element is above the overlay */
    cursor: pointer;
    transition: transform 0.3s ease, z-index 0.3s ease-in-out, background 1s ease-in-out, padding 2s ease;
}
