.my-modal{
    position: fixed!important;
    z-index: 100;
    margin: 0rem!important;
    left: -3.1dvw!important;
    right: 0rem!important;
    top: 0rem!important;
    width: 109.2dvw!important;
    height: 102dvh!important;
    overflow:none!important;
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-header{
  background-color: whitesmoke;
}

.numbers{
  margin: auto!important;
}

.btn-nextprev{
  background-color:#ffffff;
}

.portrait-image{
    aspect-ratio: 930/630!important;
    object-fit: cover!important;
    max-width: 90dvw;
    max-height: 85dvh;
    /* margin: auto!important; */
    z-index: 1;
}


.btn-danger-subtle {
  color: #ffffff!important; /* Red color */
  background-color: #2c2c2c!important;
  border-color: transparent;
}
.btn-danger-subtle:hover {
  color: #000000!important; /* Red color */
  background-color: #ffffff!important; /* Light red background on hover */
  border: 0.1rem groove rgba(0, 0, 0, 0.8)!important;
  transition: all 0.5s ease;
}

.btn-carousel {
  border-radius: 5rem!important;
  color: #000000!important; /* Red color */
  background-color: rgba(0, 0, 0, 0.6)!important;
  border: 0.1rem groove rgb(0, 0, 0)!important;
  transition: all 1s ease!important;
}
.btn-carousel:hover {
  scale: 1.5;
  color: #000000!important; /* Red color */
  background-color: #ffffff!important; /* Light red background on hover */
  border: 0.1rem groove rgba(255, 255, 255, 0.8)!important;
  transition: all 0.5s ease;
}

.carousel-next {
  /* background-image: url("../images/next.png"); */
  background-size:cover;
}

.carousel-prev {
  /* background-image: url("../images/prev.png"); */
  background-size:cover;
}

.my-carousel{
  /* margin-left: 8rem; */
  background-color: rgb(0, 0, 0, 0.8);
  backdrop-filter: blur(2.5px);
  z-index: 0;
  border: 0.2rem groove rgba(255, 255, 255, 1);
  /* You can adjust these values as needed */
  transition: all 1s ease;
}

.carousel-item {
  padding: 2rem!important;
  overflow: hidden; /* Ensure content doesn't overflow */
  transition: all 1.3s ease!important;
}

.carousel{
  border: 0.15rem groove;
  background-color: #ffffff;
  transition: all 1s ease;
}

.carousel-item.active {
  transition: all 1s ease; /* Apply smooth transition for height changes */
}

.carousel-dot{
  background: #000000!important;
  border: 0.1rem groove rgba(255, 255, 255, 1)!important;
}


.my-image{
    aspect-ratio: 16 / 9;
    width: auto;
    height: 100%;
    object-fit: contain; 
    overflow:auto;
    transition: all 1s ease;
} 

.back-button {
  min-width: 15rem;
  color: aliceblue!important;
}


.category-button {
  border: 0.1rem groove rgba(255, 255, 255, 1)!important;
  transition: all 0.5s ease!important;
}

.category-button:hover {
  scale : 1.1;
  border: 0.1rem groove rgba(255, 255, 255, 1)!important;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1) !important;
}

.container {
  display: flex;
}

.carousel-side, .menu-side {
  flex: 1;
}

.menu-side{
  /* width: 20vw; */
  background-color: rgba(255, 255, 255, 1);
  padding: 2rem;
  position: fixed;
  left: 0rem!important;
  transition: all 1s ease;
}

.close{
  border: 0.1rem groove!important;
}

.carousel-side{
  margin-left: 3rem!important;
}

.top-side{
  background-color: white;
}


@media (min-width: 2800px) {
  .menu-side {
    font-size: 4rem;
    width: 20vw;
  }

  .carousel-side{
    margin-left: 20vw!important;
  }

  .text-desc {
    font-size: 2rem !important;
  }
  .my-carousel{
    width: 40vw;
  }
  .semi-transparent{
    transition: all 1s ease;
  }
  .back-button {
    font-size: 2rem !important;
  }
  .category-button {
    min-width: 30rem;
    font-size: 3rem !important;
  }

  .txt-content {
      font-size: 3rem!important;
    }
  
}


@media (min-width: 1500px) and (max-width: 2800px) {
    .menu-side {
      font-size: 2.5rem;
      min-width: 20rem;
      width: 30vw;
    }

    .carousel-side{
      margin-left: 30vw!important;
    }
    .text-desc {
      font-size: 1.3rem !important;
    }
    .my-carousel{
      width: 65vw;
    }
    .semi-transparent{
    }
    .back-button {
      min-width: 20rem;
      font-size: 1.5rem !important;
    }
    .category-button {
      min-width: 15rem;
      font-size: 1.2rem !important;
    }
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    .menu-side {
      font-size: 2.3rem;
      width: 20vw;
    }
    .carousel-side{
      margin-left: 20vw!important;
    }
    .back-button {
      min-width: 10rem;
      font-size: 0.8rem !important;
    }
    .category-button {
      min-width: 10rem;
      font-size: 0.8rem !important;
    }
    .text-desc {
      font-size: 0.8rem !important;
    }
    .my-carousel{
      width: 70vw;
    }
  
  }



  @media (min-width: 950px) and (max-width: 1200px) {
    .menu-side {
      font-size: 2.1rem;
      width: 40vw;
    }
    .carousel-side{
      margin-left: 40vw!important;
    }
    .my-carousel{
      width: 50vw;
    }
    .back-button {
      min-width: 8rem;
      font-size: 0.8rem !important;
    }
    .category-button {
      min-width: 8rem;
      font-size: 0.8rem !important;
    }
    .text-desc {
      font-size: 0.9rem !important;
    }
  }


@media (min-width: 500px) and (max-width: 950px) {
    .menu-side {
      font-size: 1.2rem!important;
      width: 30vw;
    }
    .carousel-side{
      margin-left: 30vw!important;
    }
    .back-button {
      justify-self: start!important;
      font-size: 0.6rem !important;
      min-width: 6rem;
    }
    .category-button {
      font-size: 0.6rem !important;
      min-width: 6rem;
    }
    .text-desc {
      font-size: 0.6rem !important;
    }
    .txt-content {
        font-size: 0.8rem !important;
    }
    .my-carousel{
        border: 0.15rem groove;
        width: 62vw;
    }

    .carousel-item{
      padding: 2rem!important;
    }
  }


  @media (max-width: 500px) {
    .menu-side {
      padding: 0.5rem;
      width: 35vw;
      font-size: 1rem!important;
    }
    .carousel-side{
      margin-left: 38vw!important;
    }
    .back-button {
        font-size: 0.8rem !important;
        min-width: 6rem;
        max-height: 2rem;
    }
    .category-button {
      font-size: 0.4rem !important;
      padding: 0.3rem!important;
      min-width: 4.5rem;
    }
    .text-desc {
      font-size: 0.5rem !important;
    }

    .txt-content {
        font-size: 0.7rem !important;
      }
    .content-title{
      min-width: 4rem;
    }
    .my-carousel{
      width: 55vw!important;
    }

  }



