html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.pageBody {
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("./images/backgrounds/EK_Background.png");
  background-attachment: fixed;
  background-size: contain;
  background-position: contain;
  position: fixed;
  z-index: -1;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

.Footer{
  position: fixed;
  left:0;
  bottom:0;
  right:0;
}

mainContainer{
  width: 100%;
  height: 100%;
}

html .btn{
  border-radius: 0;
}

html .h1{

}

