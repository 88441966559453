.language{
    position: absolute;
    right:1rcap;
    width: 4rem;
    cursor: pointer;
    z-index: 3;
}

.language-en:hover{
    background-image: url("../images/flags/greek-flag.png");
    background-size: cover;
    background-blend-mode: lighten;
}

.language-gr:hover{
    background-image: url("../images/flags/uk-flag.png");
    background-size: cover;
    background-blend-mode: lighten;
}


.selected{
    background-color: #e2e2e2;
    color: red;
    border-radius: 0rem;
    scale: 1.1;
    transition: all 0.5s ease;
}

.logo-class{
    background-color: transparent;
    max-height: 5rem;
    transition: all 1s ease;
}

@media (max-width: 991.5px) {
    .language{
        position: relative;
        width: 4rem;
        margin-left: 1.5rem!important;
    }
  }


.navbar{
    z-index: 1;
}