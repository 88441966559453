
.enabled{
    background-color: rgba(255, 255, 255, 1)!important;
    color: black!important;
}

.not-enabled{
    background-color: rgba(0, 0, 0, 0.9);
    color: white!important;
}


.mycard{
    margin: 1rem;
    max-width: 24rem!important;
    z-index: 0!important;
    cursor:pointer;
    border-radius: 0 !important;
    scale: 1;
    transform: all 1s ease;
    transition: margin-left 1s ease;
    
}

.card{
    z-index: 1!important;
    border-radius: 0px!important;
    border: 0.3rem solid rgba(255, 255, 255, 0.8)!important;
    transition: all 1s ease;
}

.card-img-top{
    object-fit: cover!important;
    border-radius: 0 !important;
    aspect-ratio: 930/630;
    border: 0.3rem solid rgba(255, 255, 255, 0.5)!important;
}

.card-categories{
    margin-top: -3px!important;
    margin-right: 5px;
    margin-left: 0px;
    overflow: hidden;
    white-space: nowrap;
    transition: all 1s ease;
}

.cardContainer{
    margin-left: 17rem!important;
    justify-content: center; /* Center cards horizontally */
    transform: all 1s ease!important;
}


.filter-div{
    max-width: 25rem;
    position: absolute;
    z-index: 1!important;
    background-color: rgba(255, 255, 255, 1);
    padding: 2rem;
    left: 0rem;
    /* height: auto; */
    margin-bottom: 1.5rem!important;
    justify-content: center; /* Center cards horizontally */
    transition: all 2s ease; /* Transition for flex-basis */
}

.filter-div-title{
    cursor:pointer;
    margin-bottom: -25px;
}

.filter-div-body{
    pointer-events: all;
    max-height: 22rem;
    transition: opacity 0.3s ease-out 0.3s ,max-height 1s ease, width 1s ease;

}

.filter-div-body.closed{
    pointer-events: none;
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s ease-out ,max-height 1s ease;
}

.filters-btn{
    transition: all 1s ease;
}

.filters-btn:hover{
    scale: 1.3;
}

.categories-button{
    word-wrap: nowrap;
    text-overflow: ellipsis!important;
    width: 50%!important;
}

.regular {
    display: inline-block;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out; /* Optional: add a smooth transition */
}

.rotated {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out; /* Optional: add a smooth transition */
}


.card:hover{
    z-index: 5!important;
    scale:1.05;
    border: 0.2rem solid rgba(0, 0, 0, 0.5)!important;
    box-shadow: 0.5rem 1rem 1rem rgba(0, 0, 0, 0.1)!important;
    transition: all 1s ease;
}

.card:hover .card-bottom{
     background-color: rgba(0, 0, 0,0.8);
     color: rgb(255, 255, 255)!important;
}

.card:hover .card-bottom .card-categories{
    color: rgb(255, 255, 255)!important;
}

.card-bottom{
    flex: 1;
    background-color: #e2e2e2;
    transition: all 1s ease;
}

.my-opt{
    border-radius: 0px !important;
}

.text-body-primary{
    margin: auto;
    white-space: nowrap!important;
    text-overflow: ellipsis!important;
}

.separator{
    margin-top: 0rem!important;
}

@media (max-width: 700px) { 
    .mycard{
        max-width: 10rem!important;
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .card-img-top{
        border: 0.1rem solid rgba(255, 255, 255, 0.5)!important;
    }
    .text-body-primary{
        font-size: 0.8rem!important;
    }
    .card-categories{
        font-size: 0.7rem!important;
    }
}


@media (min-width: 700px) and (max-width: 1200px) { 
    .mycard{
        width: 18rem!important;
    }
    .text-body-primary{
        font-size: 1rem !important;
    }
    .text-body-secondary{
        font-size: 0.8rem !important;
    }
    .card-img-top{
        border: 0.1rem solid rgba(255, 255, 255, 0.5)!important;
    }
    .text-body-primary{
        font-size: 1.5rem!important;
    }
    .card-categories{
        font-size: 0.9rem!important;
    }
}

@media (max-width: 1400px) { 
    .filter-div{
        position: relative;
        padding: 1rem;
        margin-top: -2rem!important;
        max-width: 100dvw;
    }
    .cardContainer{
        margin-left: 0rem!important;
    }
}

@media (min-width: 1400px) { 
    .cardContainer{
        margin-left: 20rem!important;
    }
    .mycard{
        width: 22rem !important;
        margin-left: 1rem!important;
    }
    .filter-div{
        min-width: 20dvw;
        max-width: 25dvw;
        margin-right: 10rem;
    }
}

@media (min-width: 1700px) { 
    .mycard{
        width: 24rem !important;
        margin-left: 3rem!important;
    }
    .filter-div{
        min-width: 20dvw;
        max-width: 25dvw;
    }
    .cardContainer{
        margin-left: 30rem!important;
    }
}

.select{
    text-transform: none!important;
}