.gray-container{
    border: 0.1rem solid #e2e2e2;
    background-color: rgba(255, 255, 255, 1);
    padding: 10px;
    background-color: rgba(245, 245, 245, 0.9);
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
}

.home-logo{
    aspect-ratio: 201/54;
    margin: auto;
    width: min-content;
}

.idea-icon{
    position: absolute;
    left: 0px;
    top: 0px;
    mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 120%);
    -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
}

.design-icon{
    position: relative;
    width: 80%;
}

.size-class{
    width: 50%;
    margin: auto;
}

@media (width > 800px){
    .size-class{
        width: 50%;
        margin: auto;
    }
}

@media (width < 800px){
    .size-class{
        width: 90%;
        margin: auto;
    }
}

.home-screen{
    background-size: cover;
    background-image: url(../images/dall-e_bg.png);
    box-shadow: 1px 4px 1rem rgba(0, 0, 0, 0.3);
}

