.pagination {
    justify-content: center;
    margin: 0px 0;
    border: 1px solid #ccc;
    padding: 0.2rem;
    background-color: #e2e2e2;
  }
  
  .pagination-button {
    background-color: #ffffff;
    border: 1px solid #ccc;
    margin: 0 3px;
    padding: 5px 6px;
    /* min-width: 35px; */
    max-width: 40px;
    /* max-height: 40px; */
    cursor: pointer;
    transition: all 1.5s ease;
  }
  
  .pagination-button:hover {
    background-color: #e0e0e0;
  }
  
  .pagination-button.active {
    background-color: #3f3f3f;
    color: white;
    border-color: #ffffff;
  }
  
  .ellipsis {
    margin: 0 4px;
    padding: 5px 10px;
    cursor: default;
  }
  
  .page-content {
    text-align: center;
    margin-top: 20px;
  }
  