.search-bar {
    display: flex;
    width: 80%;
    margin: auto;
    align-items: center;
    margin-bottom: 1rem;
    border: 0.5rem solid #e2e2e2;
  }
  
  .search-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 0.5rem;
  }
  
  .search-button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  